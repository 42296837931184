(function ($) {
  /**
    * Homepage basic formatter v2 behavior
    *
    */
  Drupal.behaviors.basic_formatter_v2 = {
    attach: function (context, settings) {
      $homeBlocks = $('.js-basic-formatter-v2_row');

      // home-block add anchor links for each block to our formatter
      if ($homeBlocks.length > 0) {
        $homeBlocks.each(function (e) {
          var linkid = $(this).find('.node-elc-nodeblock').attr('id'),
            $linkid = $(linkid),
            newanchor = '<a class="js-basic-scrollto basic-scrollto" href="#' + linkid + '" data-basicblock-id="' + linkid + '"></a>',
            $appendedlink = $('.js-basic-scrollto[data-basicblock-id=' + linkid + ']');
          var rowHeight = $('#' + linkid).height();

          // add anchors
          // only if they are not already there
          if (!$appendedlink.length && rowHeight && $('#basic-formatter__scroller')) {
            $('#basic-formatter__scroller').append(newanchor);
          }
          // waypoints for custom directions so we can account for bugs in offset
          // down
          $(this).waypoint(function (direction) {
            if (direction === 'down') {
              $('.js-basic-scrollto').removeClass('block-active');
              $('.js-basic-scrollto[data-basicblock-id=' + linkid + ']').addClass('block-active');
            }
          }, { offset: 100 });
          // up
          $(this).waypoint(function (direction) {
            if (direction === 'up') {
              $('.js-basic-scrollto').removeClass('block-active');
              $('.js-basic-scrollto[data-basicblock-id=' + linkid + ']').addClass('block-active');
            }
          }, { offset: 50 });
        });
      }

      // dot nav click function
      $('.js-basic-scrollto').on('click', function (event) {
        event.preventDefault();
        var scrollLink = $(this).attr('href');

        // scrollLink = scrollLink.replace('#', '.');
        $scrollLink = $(scrollLink);
        var anchorOffset = $scrollLink.offset().top - $('.js-site-header-sticky').height() + $('.jsite-footer__main').height();

        $('html,body').animate({ scrollTop: anchorOffset }, 800);
      });
    } // attach
  }; // Drupal.behaviors.basic_formatter_v2
})(jQuery);
